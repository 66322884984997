export const footer = {
  mode: "zh",
  footer: {
    seldiInformation:
      "Seldi Co,LTD. l首尔市江南大路364MEEWANG大厦1709 l 代表：李昌宪 l 营业执照号：568-88-02012",
    seldiNumber: "电话：02-6958-8556 l 传真：0504-051-2750",
    consultingTitle: "咨询电话",
    consultingTime: `平日：09:00~18:00(午休:11:30~12:30)
    周六/周日/节假日休息`,
  },
};
