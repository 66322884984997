export const marketing = {
  mode: "zh",
  marketing: {
    title: "AI 기반 상품별 맞춤 고객",
    emphasize: "타겟 마케팅",
    description: `내 상품이 어떤 고객에게 잘 판매될지 아직 모르신다면 
      셀디의 AI 분석 기능을 통해 나의 고객을 찾아보세요`,
    link: "AI 분석 컨설팅 신청하기",
  },
};
