export const introduce = {
  mode: "ko",
  introduce: {
    title: `AI 기반 중국 수출 전문
      CRM 마케팅 솔루션, 셀디`,
    cancel: "'남들이 다 하기 때문에'",
    subTitle: `이제, '내 제품'에 최적화된
    세일즈&마케팅에 집중하세요`,
    button: "상담 신청",
  },
};
