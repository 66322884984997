export const header = {
  mode: "zh",
  header: {
    introduce: "SEL-DI介绍",
    manual: "服务流程",
    login: "登录",
    question: "咨询",
    price: "價錢",
    recruit: "招聘启事",
  },
};
