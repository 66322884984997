export const func = {
  mode: "ko",
  func: {
    title: "셀디는 '내 제품'에 최적화된 세일즈&마케팅 방법을 찾아드립니다.",
    description: `세일즈&마케팅을 진행할수록
    AI 분석을 통해 최적화 되는 내 브랜드만의 마케팅 노하우`,
    one: "맞춤분석",
    two: "매칭 서비스",
    three: "판매 최적화",
    button: "신청하기",
  },
};
