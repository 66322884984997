export const solution = {
  mode: "zh",
  solution: {
    title: "与SEL-DI合作，",
    emphasize: "就可一次搞定！",
    one: "商品管理，订单管理，物流管理，申告出口>自动化",
    two: "无条理的网红营销>优化",
    three: "分析苦难的出口数据>客制化",
    description: `从简单的出口行政业务, 到复杂的营销企划, 
    依赖经验得出的概率性的营销手段, 
    也将通过SEL-DI‘5分钟设置’进行优化。`,
  },
};
