export const introduce = {
  mode: "zh",
  introduce: {
    title: `专为出口中国服务
    CRM 营销解决方案, SEL-DI`,
    cancel: "'因为所有企业都在做？'",
    subTitle: `现在就找寻适合‘产品’的
    销售与营销吧`,
    button: "咨询一下",
  },
};
