export const header = {
  mode: "ko",
  header: {
    introduce: "셀디 소개",
    manual: "메뉴얼",
    login: "로그인",
    question: "문의하기",
    price: "가격",
    recruit: "채용공고",
  },
};
